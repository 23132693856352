import logo from './logo.svg';
import './App.css';
import "./components/Home"
import Home from "./components/Home";

function App() {
  return (
        <Home />
  );
}

export default App;
