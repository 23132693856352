import React, {useState} from 'react';
import { InlineWidget } from "react-calendly";

function Home() {
    const [activeSection, setActiveSection] = useState(1)

    return (
        <div className="Home">
            <header id="header">
                <div className="header-image">
                    <img src="../images/Logo.svg" alt="logo"/>
                </div>
                <nav className="nav">
                    <ul className="nav-group">
                        <li className="nav-group-item">
                            <a href="https://billing.stripe.com/p/login/14k6rQbvVfJk4ZW144">DASHBOARD</a>
                        </li>
                    </ul>
                </nav>
            </header>

            <div className="video-container">
                <iframe width="1200" height="600" src="https://www.youtube.com/embed/UPq2230myWU?si=GGMqPxonfYJYp0pG"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

            </div>

            <div className="intro-container">
                <h1 className="intro-title">
                    We help <strong style={{backgroundColor: 'hsl(213.74deg 49.08% 40.3%)'}}>AI SAAS
                    Startups</strong><br/>
                    fully <strong>develop & launch</strong><br/>
                    <strong>AI-powered</strong> solutions<br/>
                    with <strong>end-to-end</strong> support
                </h1>

                <img className="intro-illustration" src="./images/saas.png" alt=""/>

            </div>

            <div className="features-container">
                <img className="bg-curvy-image" src="./images/bg-curvy-desktop.svg" alt="background curvy"/>
                <h1 className="benefits-title">
                    Get access to exclusive & best in market benefits as our clients
                </h1>
                <div className="cards">
                    <div className="card">
                        <img src="./images/pause.png" alt="workflow"/>
                        <h3 className="card-title">Flexible Workflow Management</h3>
                        <p className="card-desc">
                            Pause and resume your tasks effortlessly. When you're ready to dive back in, we pick up
                            right where you left off.
                        </p>
                    </div>

                    <div className="card">
                        <img src="./images/plan.png" alt="pricing"/>
                        <h3 className="card-title">Transparent Monthly Pricing</h3>
                        <p className="card-desc">
                            Enjoy clear, fixed rates with no hidden fees. What you see is what you get, with no
                            surprises at checkout.
                        </p>
                    </div>

                    <div className="card">
                        <img src="./images/customer-satisfaction.png" alt="guarantee"/>
                        <h3 className="card-title">Satisfaction Guaranteed</h3>
                        <p className="card-desc">
                            Not thrilled with our work? Request a full refund within the first month—no questions asked.
                        </p>
                    </div>

                    <div className="card">
                        <img src="./images/diagram.png" alt="adaptable"/>
                        <h3 className="card-title">Adaptable and Growth-Oriented</h3>
                        <p className="card-desc">
                            Easily adjust your service level as needed. Whether you need more or less, our flexible
                            plans scale to fit your needs.
                        </p>
                    </div>

                    <div className="card">
                        <img src="./images/private-key.png" alt="exclusive"/>
                        <h3 className="card-title">Exclusively Yours</h3>
                        <p className="card-desc">
                            Every piece of work is custom-crafted just for you and is entirely yours to keep.
                        </p>
                    </div>

                    <div className="card">
                        <img src="./images/list.png" alt="unlimited"/>
                        <h3 className="card-title">Unlimited Task Submissions</h3>
                        <p className="card-desc">
                            Feel free to add as many tasks as you need to your queue. We handle them all, without
                            limits.
                        </p>
                    </div>

                    <div className="card">
                        <img src="./images/high-quality.png" alt="quality"/>
                        <h3 className="card-title">Exceptional Quality, Always</h3>
                        <p className="card-desc">
                            Access top-tier development and results whenever you need them, maintaining the highest
                            standards.
                        </p>
                    </div>

                    <div className="card">
                        <img src="./images/quick.png" alt="turnaround"/>
                        <h3 className="card-title">Rapid Turnaround</h3>
                        <p className="card-desc">
                            We prioritize speed and efficiency, typically delivering tasks within 2-4 business days.
                        </p>
                    </div>

                    <div className="card">
                        <img src="./images/people.png" alt="community"/>
                        <h3 className="card-title">Private Community of AI SaaS Founders</h3>
                        <p className="card-desc">
                            Connect with like-minded founders in our exclusive community, sharing insights and support
                            to grow together.
                        </p>
                    </div>
                </div>
            </div>

            <section className="features">
                <div className="title">
                    <h2>Services</h2>
                </div>
                <div className="desc">
                    <p>
                        We offer complete development services to bring your SaaS ideas to life. Whether you need a
                        user-friendly interface, a strong backend, or a ready-to-launch MVP, we’re here to help from
                        start to finish.
                    </p>
                </div>
                <nav className="features-nav">
                    <div onClick={() => setActiveSection(1)} id="col"
                         className={`col-1 ${activeSection === 1 && "active"}`}>
                        <p id="col-item" className="col-1-item tab-1">Front-End Development</p>
                    </div>
                    <div onClick={() => setActiveSection(2)} id="col"
                         className={`col-2 ${activeSection === 2 && "active"}`}>
                        <p id="col-item" className="col-2-item tab-2">Back-End Development</p>
                    </div>
                    <div onClick={() => setActiveSection(3)} id="col"
                         className={`col-3 ${activeSection === 3 && "active"}`}>
                        <p id="col-item" className="col-3-item tab-3">MVP Development</p>
                    </div>
                </nav>
            </section>

            <section onClick={() => setActiveSection(1)} id="section-with-illustration"
                     className={`more-info tab-1 ${activeSection === 1 && "active"}`}>
                <div className="left">
                    <h1 className="moreInfo-title">Front-End Development</h1>
                    <p className="moreInfo-desc">
                        We design and develop visually appealing, responsive interfaces that ensure your AI SaaS product
                        is both attractive and easy to use. Our focus is on delivering a seamless user experience,
                        making your product intuitive and accessible across all devices.
                    </p>
                    <a href="#book-call">
                        <button id="button" className="section-left-btn blue-btn">
                            Let's build
                        </button>
                    </a>
                </div>
                <div className="right">
                    <img className="moreInfo-img" src="../images/web.png" alt="illustration"/>
                </div>
            </section>

            <section onClick={() => setActiveSection(2)} id="section-with-illustration"
                     className={`more-info tab-2 ${activeSection === 2 && "active"}`}>
                <div className="left">
                    <h1 className="moreInfo-title">Back-End Development</h1>
                    <p className="moreInfo-desc">
                        We build the core infrastructure of your AI SaaS product, creating robust, secure, and scalable
                        server-side systems. Our solutions ensure your application runs smoothly and can handle complex
                        processes efficiently, providing a reliable foundation for your product's success.
                    </p>
                    <a href="#book-call">
                        <button id="button" className="section-left-btn blue-btn">
                            Let's build
                        </button>
                    </a>
                </div>
                <div className="right">
                    <img className="moreInfo-img" src="./images/backend.png" alt="illustration"/>
                </div>
            </section>

            <section id="section-with-illustration" className={`more-info tab-3 ${activeSection === 3 && "active"}`}>
                <div className="left">
                    <h1 className="moreInfo-title">MVP Development</h1>
                    <p className="moreInfo-desc">
                        We specialize in quickly developing a Minimum Viable Product (MVP) for your AI SaaS idea,
                        allowing you to test and validate your concept in the market. This approach enables rapid
                        feedback and iteration, helping you refine your product and bring it to market faster.
                    </p>
                    <a href="#book-call">
                        <button id="button" className="section-left-btn blue-btn">
                            Let's build
                        </button>
                    </a>
                </div>
                <div className="right">
                    <img className="moreInfo-img" src="./images/mvp.png" alt="illustration"/>
                </div>
            </section>

            <div className="price-body">
                <div className="price-container">
                    <div className="price-header">
                        <p className="header-title one">Optimize & Expand</p>
                        <p className="header-title two">
                            30-day Satisfaction Guarantee
                        </p>
                        <p className="header-description">
                            Gain access to our team of professionals
                        </p>
                        <p className="header-description">
                            Perfect for any AI SAAS Business that wants to improve their product design or functionality
                        </p>
                    </div>
                    <div className="price-bottom">
                        <div className="price-bottom-cards">
                            <div className="price-bottom-card left">
                                <h3 className="price-title">Monthly Subscription</h3>
                                <div className="row-middle">
                                    <strong className="card-price">$1499-$3999</strong>
                                    <span className="row-two span">per month</span>
                                </div>
                                <p>Full access to our experts for less than $50 a day</p>
                                <a href="#book-call">
                                    <button className="sign-button">Let's improve product</button>
                                </a>
                            </div>
                            <div className="price-bottom-card right">
                                <h3 className="price-title">What's Included</h3>
                                <ul className="list-group">
                                    <li className="list-group-item">High-Quality work and communication</li>
                                    <li className="list-group-item">Personalized task tracking board</li>
                                    <li className="list-group-item">Pause or cancel anytime</li>
                                    <li className="list-group-item">Easy credit-card payments</li>
                                    <li className="list-group-item">Signed NDA if necessary</li>
                                    <li className="list-group-item">Private community of AI SAAS Founders</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="price-container">
                    <div className="price-header">
                        <p className="header-title one">Idea-to-Market Transformation</p>
                        <p className="header-title two">
                            30-day Satisfaction Guarantee
                        </p>
                        <p className="header-description">
                            Gain access to our team of professionals
                        </p>
                        <p className="header-description">
                            Perfect for AI SAAS Startups looking to turn their innovative ideas into fully functional,
                            market-ready products.
                        </p>
                    </div>
                    <div className="price-bottom">
                        <div className="price-bottom-cards">
                            <div className="price-bottom-card left">
                                <div>
                                    {/*<h3 className="price-title">Monthly Subscription</h3>*/}
                                    <div className="row-middle">
                                        <strong className="card-price">CUSTOM</strong>
                                    </div>

                                    <p>Based on the complexity of the AI SAAS Idea, we will provide a fixed price
                                        proposal.</p>
                                </div>

                                <a href="#book-call">
                                    <button className="sign-button">Let's Turn Idea Into Reality</button>
                                </a>
                            </div>
                            <div className="price-bottom-card right">
                                <h3 className="price-title">What's Included</h3>
                                <ul className="list-group">
                                    <li className="list-group-item">High-Quality work and communication</li>
                                    <li className="list-group-item">Personalized task tracking board</li>
                                    <li className="list-group-item">Pause or cancel anytime</li>
                                    <li className="list-group-item">Easy credit-card payments</li>
                                    <li className="list-group-item">Signed NDA if necessary</li>
                                    <li className="list-group-item">Private community of AI SAAS Founders</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-founder">
                <div className="about-founder-container">
                    <div className="founder-photo">
                        <img src="./images/me3.jpg" alt="Founder Photo"/>
                    </div>
                    <div className="founder-text">
                        <h2>About the Founder</h2>
                        <p>At just 21, Davids has already achieved a lot in software development. He taught himself to
                            code because of his strong passion for technology and started working as a backend developer
                            in the iGaming industry at 18. Over the past three years, Davids has sharpened his skills,
                            which inspired him to start SAASABLE. His goal is to use his love for SaaS and AI
                            technologies to help startups turn their ideas into successful products. Davids is committed
                            to delivering great results and is dedicated to helping others with his expertise and
                            passion for tech innovation.</p>
                    </div>
                </div>
            </div>

            <div id="book-call" className="calendly-container">
                <InlineWidget
                    url="https://calendly.com/aisaasable/saasable?hide_gdpr_banner=1&background_color=181f2a&text_color=faf7f7&primary_color=0b6efd&height=665px"
                />
            </div>
            <div className="footer-container">
                <div className="footer-logo">
                    <img src="./images/Logo.svg" alt="logo"/>
                </div>
                <div className="footer-information">
                    <div className="information-col col-2">
                        <div className="info-block">
                            <div className="information-row row-1">
                                <img src="./images/icon-phone.svg" alt="phone"/>
                                <p>+371 20281684</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Home;
